import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import AlarmIcon from '@mui/icons-material/Alarm';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import { Grid } from '@mui/material';
import { LinkedIn, Twitter, Email } from '@mui/icons-material';

export default function IconButtons() {
    return (
        <Grid container justifyContent='center' >

            <Stack direction="row" spacing={1}>
                <IconButton target='_blank' href='https://www.linkedin.com/company/phonica-org/'>
                    <LinkedIn />
                </IconButton>
                <IconButton target='_blank' href='https://twitter.com/phonicaspeech'>
                    <Twitter />
                </IconButton>
                <IconButton target='_blank' href='mailto:akhil@phonica.org'>
                    <Email />
                </IconButton>
            </Stack>
        </Grid>
        
    );
}
